<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        일산연수원 운동장 이용안내
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="educourse-view-container course-cancel">
        <div class="view-contents">
          <section class="content-section">
            <header class="section-header header-divider">
              <h3 class="title">이용안내</h3>
            </header>
            <div class="section-content">
              <ul class="text-list">
                <li class="text font-body14" data-bullet="1.">신청자 2인은 이용시간 30분전까지 연수원 도착, 본인확인 후 정문에 비치되어 있는 이용신청서에 서명</li>
                <li class="text font-body14" data-bullet="">&nbsp;</li>
                <li class="text font-body14" data-bullet="2.">신청자 2인은 이용시간 내 상주, 이용직원 인솔 관리감독</li>
                <li class="text font-body14 text-muted" data-bullet="">(단, 신청자가 m-WiseNet을 통해 신분증 미지참자 본인확인 지원시 출입 가능 > 본인확인 불가시 출입 통제)</li>
                <li class="text font-body14" data-bullet="">&nbsp;</li>
                <li class="text font-body14" data-bullet="3.">이용시간 준수 및 이용 종료 후 운동장 주변 정리 ∙ 정돈을 부탁 드립니다.</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="nextStep">등록</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, ref} from 'vue';
import {useStore} from "vuex";

import {useRouter} from "vue-router";
import {ACT_INSERT_FLCT_FSVT_APLY} from "@/store/modules/trnct/trnct";
import {isSuccess} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'DailyMobileSportsApplyStep4',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage,showConfirm} = useAlert();

    const session = computed(() => store.state.auth.session);
    const fcltRsvtAplyIns = computed(() => store.state.trnct.mobileSportsApplyInfo);

    const reserveVenue = ref('체육시설');



    const nextCheck = () => {
      console.log('dd');
    }

    const nextStep = async () => {
      showConfirm({
          text: '예약 신청 하시겠습니까?',
          callback: async () => {         
            await store.dispatch(`trnct/${ACT_INSERT_FLCT_FSVT_APLY}`, store.state.trnct.mobileSportsApplyInfo).then(res => {
              console.log(res);
              if (isSuccess(res)) {
                //clearFcltAplyIns();

              }else{
                showMessage('월 신청 회수 (월1회)를 초과하였습니다.');
              }
            }).catch((e) => {console.error(e);})

            router.push('/daily/apply');
          }
      });
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      reserveVenue,
      session,
      nextCheck,
      nextStep,
      fcltRsvtAplyIns,
      goBack
    };
  },
};
</script>
